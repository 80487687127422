<template>
  <v-toolbar
    dense
    flat
  >
    <v-toolbar-title
      v-if="title !== null"
      :class="`${textColor}--text`"
    >
      <div>
        <v-icon v-if="icon !== null" class="primary--text mr-0 mb-1" left>{{ icon }}</v-icon>
        {{ title }}
      </div>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <slot></slot>

    <!-- Button display. -->
    <template v-if="displayButtonsAsButtons">
      <TooltipFabIconButton
        v-for="(item, i) in items"
        :key="item.text"
        @click="item.click"
        bottom
        :class="(i === items.length - 1) ? '' : 'mr-2'"
        :color="item.color"
        :disabled="item.disabled"
        :icon="true"
        :icon-name="item.icon"
        :text="item.text"
      />
    </template>
    <!-- Menu display. -->
    <v-menu
      v-else-if="displayButtonsAsMenu"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>{{ DOT_MENU_ICON }}</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          @click="item.click"
        >
          <v-list-item-icon>
            <v-icon :color="item.color" v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title :class="item.textColor" v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Tab Items -->
    <template
      v-if="hasTabItems"
      v-slot:extension
    >
      <v-tabs
        v-model="inputTab"
        align-with-title
        class="ml-2"
      >
        <v-tooltip
          v-for="(item, i) in tabItems"
          :key="i"
          bottom
          :open-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-tab
              v-bind="attrs"
              v-on="on"
            >
              <v-badge
                v-if="item.displayBadge && item.badgeContent"
                bordered
                color="primary"
                :content="item.badgeContent"
                inline
              >
                <v-icon>{{ item.icon }}</v-icon>
              </v-badge>
              <v-icon v-else>{{ item.icon }}</v-icon>
            </v-tab>
          </template>
          <span>{{ item.tooltip }}</span>
        </v-tooltip>
      </v-tabs>
    </template>
  </v-toolbar>
</template>

<script>
import { mapActions } from 'vuex'

import { COLOR, ICONS, PRIMARY_KEY, TEXT_COLOR, } from '../../../constants'
import { push } from '../../../router'

export default {
  components: {
    TooltipFabIconButton: () => import('../../../components/input/TooltipFabIconButton')
  },
  props: {
    backButtonOnly: { type: Boolean, default: false, },
    backButtonRoute: { type: String,  default: '', },
    backButtonTooltip: { type: String,  default: '', },
    buttonMode: { type: Boolean, default: false, },
    disableAdd: { type: Boolean, default: false, },
    disableAddEventGroup: { type: Boolean, default: false, },
    disableDelete: { type: Boolean, default: false, },
    disableRefresh: { type: Boolean, default: false, },
    disableSave: { type: Boolean, default: false, },
    disableUpdateEvent: { type: Boolean, default: false, },
    icon: { type: String,  default: null },
    includeAdd: { type: Boolean, default: false, },
    includeAddEventGroup: { type: Boolean, default: false, },
    includeBack: { type: Boolean, default: false, },
    includeClose: { type: Boolean, default: false, },
    includeCollapse: { type: Boolean, default: false, },
    includeDelete: { type: Boolean, default: false, },
    includeExpand: { type: Boolean, default: false, },
    includeHelp: { type: Boolean, default: false, },
    includeRefresh: { type: Boolean, default: false, },
    includeSave: { type: Boolean, default: false, },
    includeUpdateEvent: { type: Boolean, defaut: false, },
    menuMode: { type: Boolean, default: true, },
    tab: { type: Number, default: 0, },
    tabItems: { type: Array, default: () => [], },
    textColor: { type: String,  default: COLOR.PRIMARY, },
    title: { type: String,  default: null, },
  },
  computed: {
    inputTab: {
      get() {
        return this.tab
      },
      set(tab) {
        this.$emit('tab-change', tab)
      }
    },
    breakpoint() {
      return this.$vuetify.breakpoint.name
    },
    displayButtonsAsButtons() {
      return this.buttonMode && !this.isXsBreakpoint
    },
    displayButtonsAsMenu() {
      return (this.menuMode || this.isXsBreakpoint) && this.items.length > 0
    },
    hasTabItems() {
      return this.tabItems.length > 0
    },
    isXsBreakpoint() {
      return this.breakpoint === 'xs'
    },
    items () {
      let buttons = [
        {
          click: () => { this.toggleHelp() },
          color: COLOR.INFO,
          disabled: false,
          icon: ICONS.HELP,
          text: "Help",
          textColor: TEXT_COLOR.INFO,
          included: this.includeHelp
        },
        {
          click: () => { this.$emit('collapse-all') },
          color: COLOR.SECONDARY,
          disabled: false,
          icon: ICONS.COLLAPSE,
          text: "Collapse All",
          textColor: TEXT_COLOR.SECONDARY,
          included: this.includeCollapse,
        },
        {
          click: () => { this.$emit('expand-all') },
          color: COLOR.SECONDARY,
          disabled: false,
          icon: ICONS.EXPAND,
          text: "Expand All",
          textColor: TEXT_COLOR.SECONDARY,
          included: this.includeExpand,
        },
        {
          click: () => { this.$emit('refresh-data') },
          color: COLOR.PRIMARY,
          disabled: this.disableRefresh,
          icon: ICONS.REFRESH,
          text: "Refresh",
          textColor: PRIMARY_KEY,
          included: this.includeRefresh
        },
        {
          click: () => { this.$emit('delete-data') },
          color: COLOR.ERROR,
          disabled: this.disableDelete,
          icon: ICONS.DELETE,
          text: "Delete",
          textColor: TEXT_COLOR.ERROR,
          included: this.includeDelete
        },
        {
          click: () => { this.$emit('update-event-data') },
          color: COLOR.SUCCESS,
          disabled: this.disableUpdateEvent,
          icon: ICONS.CALENDAR_EVENT_UPDATE,
          text: "Update Event",
          textColor: TEXT_COLOR.SUCCESS,
          included: this.includeUpdateEvent
        },
        {
          click: () => { this.$emit('add-data') },
          color: COLOR.SUCCESS,
          disabled: this.disableAdd,
          icon: ICONS.ADD_GENERIC,
          text: "Add",
          textColor: TEXT_COLOR.SUCCESS,
          included: this.includeAdd
        },
        {
          click: () => { this.$emit('add-event-group') },
          color: COLOR.SUCCESS,
          disabled: this.disableAddEventGroup,
          icon: ICONS.CALENDAR_MULTIPLE,
          text: "Add Group",
          textColor: TEXT_COLOR.SUCCESS,
          included: this.includeAddEventGroup
        },  
        {
          click: () => { this.$emit('save-data') },
          color: COLOR.SUCCESS,
          disabled: this.disableSave,
          icon: ICONS.SAVE,
          text: "Save",
          textColor: TEXT_COLOR.SUCCESS,
          included: this.includeSave
        },
        {
          click: () => { push(this.backButtonRoute) },
          color: COLOR.SECONDARY,
          disabled: false,
          icon: ICONS.BACK,
          text: this.backButtonTooltip,
          textColor: TEXT_COLOR.SECONDARY,
          included: this.includeBack
        },
        {
          click: () => { this.$emit('close') },
          color: COLOR.SECONDARY,
          disabled: false,
          icon: ICONS.CLOSE,
          text: "Close",
          textColor: TEXT_COLOR.SECONDARY,
          included: this.includeClose
        },
      ]

      return buttons.filter(button => button.included)
    }
  },
  methods: {
    ...mapActions({
      toggleHelp: 'help/toggleHelp',
    }),
    initTemplateConstants() {
      this.DOT_MENU_ICON = ICONS.DOT_MENU
    }
  },
  created() {
    this.initTemplateConstants()
  }
}
</script>